<template>
  <b-modal
    ref="sets-modal"
    hide-footer
    title="ตั้งค่าหวยชุด"
    ok-variant="success"
    @show="setData"
    @hidden="hideModal"
  >
    <form
      id="market-set-form"
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <b-form-group
        label="ตั้งค่าให้"
        label-for="input-market-title"
      >
        <b-form-input
          id="input-market-title"
          type="text"
          placeholder="ชื่อใช้งาน"
          disabled
          :value="username"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="หวย"
        label-for="input-market-title"
      >
        <b-form-input
          id="input-market-title"
          type="text"
          placeholder="หวย"
          disabled
          :value="marketTitle"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="อัตราจ่าย"
        label-for="input-market-title"
      >
        <b-form-input
          id="input-market-title"
          type="text"
          placeholder="อัตราจ่าย"
          disabled
          :value="rateTitle"
        ></b-form-input>
      </b-form-group>

      <div class="row">
        <div class="col">
          <b-form-group
            label="ราคา (บาท/ชุด)"
          >
            <b-form-input
              type="text"
              :value="price | amountFormat(2, '0.00')"
              disabled
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col">
          <b-form-group
            label="ค่าคอมฯ (บาท/ชุด)"
          >
            <b-input-group size="md" :append="refDiscount | amountFormat(2, '0.00')">
              <b-form-input
                type="text"
                v-model="input.discount"
                placeholder="ค่าคอมฯ (บาท/ชุด)"
                :state="input.discount <= refDiscount"
                :number="true"
                required
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </div>
      </div>

      <b-form-group
        label="ได้รับส่วนแบ่ง (%)"
      >
        <b-form-input
          type="text"
          placeholder="ได้รับ %"
          disabled
          :value="refReceive | amountFormat(2, '0.00')"
        ></b-form-input>
      </b-form-group>

      <div class="row">
        <div class="col">
          <b-form-group
            label="แบ่งให้เอเย่นต์ (%)"
          >
            <b-form-select
              :options="shareOptions"
              v-model="input.share.receive"
              size="md"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="col">
          <b-form-group
            label="เก็บของเข้าตัว (%)"
          >
            <b-form-input
              type="text"
              placeholder="ได้รับ %"
              disabled
              :value="keepValue | amountFormat(2, '0.00')"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="my-1">
        <b-form-checkbox
          v-model="input.share.over"
          :value="true"
          :unchecked-value="false"
        >
          เก็บของเกิน
        </b-form-checkbox>
      </div>

      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'
import AccountSettingService from "@/services/AccountSettingService"

export default {
  name: 'EditSetsModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    market: {
      type: Object,
      default: null
    },
    price: {
      type: Number,
      default: 0
    },
    rate: {
      type: Object,
      default: null
    },
    account: {
      type: Object,
      default: null
    },
    refRate: {
      type: Object,
      default: null
    },
    refAccountId: {
      type: String,
      default: null
    },
    refReceive: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      input: {
        discount: 0,
        share: {
          over: false,
          receive: 0,
          referral: 0
        }
      },
      isProcess: false,
      needReload: false
    }
  },
  computed: {
    accountId() {
      return this.account?._id
    },
    username() {
      return this.account?.user?.username
    },
    marketId() {
      return this.market?._id
    },
    marketTitle() {
      return this.market?.marketTitle
    },
    rateId() {
      return this.rate?._id
    },
    rateTitle() {
      return this.rate?.rateTitle
    },
    refDiscount() {
      return this.refRate?.discount ?? 0
    },
    shareOptions() {
      const maxShare = this.refReceive
      let sharePercents = []
      for(let i=maxShare; i>=0; i-=0.5) {
        sharePercents.push({
          text: i.toFixed(1),
          value: i
        })
      }
      return sharePercents
    },
    keepValue() {
      const keep = this.refReceive - this.input.share.receive
      this.input.share.referral = keep
      return keep
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    setData() {
      this.needReload = false
      this.input = JSON.parse(JSON.stringify(this.account.sets[this.marketId].rates[this.rateId]))
    },
    showModal() {
      this.$refs['sets-modal'].show()
    },
    hideModal() {
      this.$refs['sets-modal'].hide()
    },
    handleSubmit() {

      const numberPattern = /^-?\d+(\.\d{1,2})?$/
      if(numberPattern.test(this.input.discount) && this.input.discount > this.refDiscount) {
        return Swal.fire({
          title: 'ผิดพลาด!',
          text: `กรุณากรอกค่าคอมฯ น้อยกว่าหรือเท่ากับ ${this.refDiscount}`,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }else
      if(this.input.discount && !numberPattern.test(this.input.discount)) {
        return Swal.fire({
          title: 'ผิดพลาด!',
          text: `ค่าคอมฯต้องเป็นตัวเลขเท่านั้น`,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      const updateData = {
        accountIds: [this.accountId],
        refAccountId: this.refAccountId,
        marketId: this.marketId,
        rateId: this.rateId,
        update: {
          discount: this.input.discount,
          share: this.input.share
        }
      }
      this.save(updateData)
    },
    save(data) {
      AccountSettingService.updateAccountSettingSets(data)
      .then((response)=>{
        if(response.success) {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          title: 'ไม่สำเร็จ!',
          text: 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
<style lang="scss" scoped>
.table {
  tr {
    td {
      vertical-align: middle;
    }
  }
}
</style>
